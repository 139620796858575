import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { gsap } from "gsap"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { ScrollScene } from "scrollscene"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"

const getPortfolioContent = graphql`
  {
    heroBgImg: allWordpressWpHero {
      edges {
        node {
          acf {
            portfolio_hero {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    bgImg: file(relativePath: { eq: "m4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    projects: allWordpressWpProject {
      edges {
        node {
          id
          slug
          title
          path
          acf {
            hero {
              project_title
              project_subtitle
            }
            summary {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const PortfolioPage = () => {
  const { projects, heroBgImg, bgImg } = useStaticQuery(getPortfolioContent)
  const portfolioHeroImg =
    heroBgImg.edges[0].node.acf.portfolio_hero.localFile.childImageSharp.fluid
  const bgImage = bgImg.childImageSharp.fluid

  let headerContainer = useRef(null)
  let headerLogo = useRef(null)
  let headerMenu = useRef(null)

  useEffect(() => {
    //GSAP Animations
    const headerAnimation = gsap.timeline()

    headerAnimation
      .set(headerContainer.current, {
        visibility: "visible",
      })
      .from([headerLogo.current, headerMenu.current], {
        duration: 0.5,
        y: 10,
        autoAlpha: 0,
        ease: "power3.inOut",
        stagger: 0.2,
        delay: 2,
      })
  }, [])

  useEffect(() => {
    // Project Card Reveal Animation Class
    let revealRow = Array.prototype.slice.call(
      document.querySelectorAll(".animateCardReveal")
    )
    let self = this

    const cardRevealAnimation = gsap.timeline({ paused: true })

    cardRevealAnimation.from(revealRow, {
      autoAlpha: 0,
      y: 30,
      ease: "power3.inOut",
      stagger: {
        amount: 0.8,
      },
    })

    const cardScrollScene = new ScrollScene({
      triggerElement: ".portfolio-grid",
      gsap: {
        timeline: cardRevealAnimation,
      },
      whenVisible: "15%",
      scene: {
        reverse: false,
        triggerHook: 0.75,
      },
    })

    cardScrollScene.Scene.on("enter", function(e) {
      console.log("card scene entered")
    })

    return () => {
      cardScrollScene.destroy()
      console.log("destroyed")
    }
  }, [])

  return (
    <Layout
      ref={{
        headerLogo: headerLogo,
        headerMenu: headerMenu,
        headerContainer: headerContainer,
      }}
    >
      <SEO title="Portfolio" />
      <Hero
        heroTitle={`Portfolio`}
        heroSubtitle={`Check out my latest projects!`}
        heroBgImg={portfolioHeroImg}
        bgImg={bgImage}
      />
      <section className="section-portfolio-grid fixed-width-container">
        <div className="portfolio-grid">
          {projects.edges.map(({ node }) => {
            const img = node.acf.summary.image.localFile.childImageSharp.fluid
            const heading = node.acf.hero.project_title
            const subheading = node.acf.hero.project_subtitle
            const link = node.path
            return (
              <div
                className="portfolio-grid__col animateCardReveal"
                key={node.id}
              >
                <AniLink
                  className="portfolio-grid__link"
                  to={link}
                  cover
                  direction="down"
                  bg="#000"
                  top="exit"
                >
                  <Img fluid={img} className="portfolio-grid__img" />
                  <div className="portfolio-grid__content">
                    <h2 className="heading-secondary portfolio-grid__heading">
                      {heading}
                    </h2>
                    <h3 className="heading-tertiary portfolio-grid__subheading">
                      {subheading}
                    </h3>
                  </div>
                </AniLink>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default PortfolioPage
